import React, { useEffect, useContext, useState, useMemo, memo } from "react"
import styled from "styled-components"
import BranchContext from "../../context/BranchContext"
import CartContext from "../../context/CartContext"
import ProductContext from "../../context/ProductContext"
import { Container } from "./Layout"
import Crumbs from "./Crumbs"
import Slider from "./Slider"
import Filter from "./Filter"
import Details from "./Details"
import CreditOptions from "./CreditOptions"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ListCheck from "../../zzz/atoms/icons/files/list-check.jsx"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"
import Delivery from "../../zzz/atoms/icons/files/delivery.jsx"
import Info from "../../zzz/atoms/icons/files/info.jsx"
import {
  path,
  shippingClassGet,
  lookupShippingClass,
  formatDeliveryInfo,
} from "../../lib/util"
import { Responsive, windowSizes } from "../../utils/responsive"
import Tooltip from "./Tooltip"
import { graphql, useStaticQuery } from "gatsby"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import { addToCompare } from "../Products/CompareProducts"
import Button from "./Button"
import { slugify } from "../../lib/stringHandling"
import { requestFeverTreeInstalment } from "../../lib/woocommApi"

import Inner from "../../zzz/layout/pageInner/Inner"
import { GatsbyImage } from "gatsby-plugin-image"

// import MealsOnWheels from "../../zzz/molecules/mealsOnWheels"

const maxSelectableStock = 99

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ProductVariationSelector = ({
  product,
  selected,
  setSelected,
  variations,
  productStock,
  productRating,
  setGoToRatingsValue,
  selectedVariationImage,
  promotionalItems,
}) => {
  const { selectedBranch } = useContext(BranchContext)
  const { addToCart, setCartDrawerOpen, setCompareDrawerOpen } =
    useContext(CartContext)
  const { setCurrentProduct } = useContext(ProductContext)

  const {
    floatImage: {
      childImageSharp: { gatsbyImageData: float },
    },
  } = useStaticQuery(QUERY)

  const [feverTreeInstalments, setFeverTreeInstalments] = useState({})
  const [counterFocus, setCounterFocus] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [apiError, setApiError] = useState("")
  const [shippingClass, setShippingClass] = useState(
    selected.main.shipping_class || product.shipping_class
  )
  const [productStockFetched, setProductStockFetched] = useState(false)

  const queryResponse = useStaticQuery(QUERY)

  const productType = useMemo(() => {
    if (product.bundled_items && product.bundled_items.length) {
      return "bundle"
    } else if (product.product_variations.length > 0) {
      return "variable"
    } else {
      return "simple"
    }
  }, [product])

  useEffect(() => {
    if (
      !productStock ||
      (Array.isArray(productStock) && !productStock.length) ||
      Object.keys(productStock).length === 0
    ) {
      setProductStockFetched(false)
    } else {
      // Prepare an array with all the possible product ID's to check stock with
      let stockIdArray = []
      if (selected.main && selected.main.id) {
        if (Array.isArray(selected.main.stock_id)) {
          stockIdArray.push(...selected.main.stock_id)
        } else if (Array.isArray(selected.main.id)) {
          stockIdArray.push(...selected.main.id)
        } else {
          stockIdArray.push(selected.main.id)
        }
      } else {
        stockIdArray.push(product.wordpress_id)
      }
      // Iterate and use the lowest stock item as the max
      const stockUpdateData = []
      let shippingClass_temp =
        selected.main.shipping_class || product.shipping_class
      let locationStockCounter = 0
      let locationEffectiveStock = null
      let locationSoh = null
      for (const stockId of stockIdArray) {
        if (productStock[stockId] && productStock[stockId].manage_stock) {
          stockUpdateData.push(productStock[stockId])

          const stockByLoc = productStock[stockId].stock_by_location
          if (selectedBranch && stockByLoc && selectedBranch in stockByLoc) {
            const locStock = stockByLoc[selectedBranch]
            if (locStock && "soh" in locStock && "effective" in locStock) {
              if (locationStockCounter === 0) {
                locationEffectiveStock = locStock.effective
                locationSoh = locStock.soh
              } else {
                locationEffectiveStock = Math.min(
                  locStock.effective,
                  locationEffectiveStock
                )
                locationSoh = Math.min(locStock.soh, locationSoh)
              }
              locationStockCounter++
            }
          }
        }
      }

      if (locationStockCounter == Object.keys(stockIdArray).length) {
        shippingClass_temp = lookupShippingClass({
          soh: locationSoh,
          eff: locationEffectiveStock,
        })
      }

      setCurrentProduct({
        name: product.name,
        image: product?.images?.[0],
        stockUpdateData,
      })
      setProductStockFetched(true)
      setShippingClass(shippingClass_temp)
    }
  }, [selected, productStock, selectedBranch])

  // const promotional = getBogofs(queryResponse, product.wordpress_id)
  const images = getFluidImagesFromProduct(product)

  // let imagesInSlider = useMemo(
  //   () =>
  //     selectedVariationImage ? [selectedVariationImage, ...images] : images,
  //   [selectedVariationImage, images]
  // )

  let imagesInSlider = images

  const handleAddToCart = () => {
    setAddToCartLoading(true)
    let addAllToCart = []
    // Product Bundles require array of variation_id and bundle_id
    if (product.bundled_items && product.bundled_items.length > 0) {
      const variation_array = []

      Object.values(selected).forEach((item) => {
        if (item.id && item.id.length > 0) {
          variation_array.push(...item.id)
        } else {
          variation_array.push(item.id)
        }
      })
      const bundle_data = {
        variation_id: variation_array,
        bundle_id: product.wordpress_id,
        quantity,
      }
      addAllToCart.push(addToCart(bundle_data))
      // Variation require variation_id or array of variation_id
    } else {
      const id_name =
        product.product_variations && product.product_variations.length > 0
          ? "variation_id"
          : "product_id"
      if (id_name === "variation_id") {
        // Add as array of varation ID's (fast!)
        const allIdArrays = Object.values(selected).map((item) => item.id)
        const mergedIds = [].concat.apply([], allIdArrays)
        addAllToCart.push(addToCart({ [id_name]: mergedIds, quantity }))
      } else {
        // Add each item individually (slow!)
        Object.values(selected).forEach((item) => {
          if (item.id && item.id.length > 0) {
            addAllToCart.push(
              ...item.id.map((id) => addToCart({ [id_name]: id, quantity }))
            )
          } else {
            addAllToCart.push(addToCart({ [id_name]: item.id, quantity }))
          }
        })
      }
    }
    // Normal product require product_id
    Promise.all(addAllToCart)
      .then(() => {
        setApiError("")
        setAddToCartLoading(false)
        setCartDrawerOpen(true)
      })
      .catch(async (error) => {
        let errorString = "Failed to add product to cart"
        if (error.message) {
          errorString = error.message
          console.log("Error Response: ", errorString)
        } else {
          console.log("Error Response: ", error)
        }
        setApiError(errorString)
        setAddToCartLoading(false)
      })
  }

  const final_price = getCompletePrice(selected)
  const final_regular_price = getCompleteRegularPrice(selected)
  // Round the price, used for FeverTree
  const rounded_price = Math.round(final_price)

  // Check and build the link to the mattress if this is a bed
  const mattressLink = useMemo(() => {
    if (
      product.bundled_items &&
      product.bundled_items.length &&
      product.categories.some(({ slug }) => slug == "beds")
    ) {
      const matchingMattress = product.bundled_items.find((pr) =>
        pr.categories.some(({ slug }) => slug == "mattresses")
      )
      const selectedsize =
        selected.main &&
        selected.main.value &&
        // `?size=${slugify(selected.main.value)}`
        `?option=${slugify(selected.main.value)}`
      return (
        matchingMattress.slug &&
        `/product/${matchingMattress.slug}/${selectedsize}`
      )
    }
    return false
  }, [product, selected])
  // Check and build the link to the bed if this is a mattress
  const bedLink = useMemo(() => {
    if (
      product.bundled_by &&
      product.bundled_by.length &&
      product.categories.some(({ slug }) => slug == "mattresses")
    ) {
      const matchingBed = product.bundled_by[0]
      const selectedsize =
        selected.main &&
        selected.main.value &&
        // `?size=${slugify(selected.main.value)}`
        `?option=${slugify(selected.main.value)}`
      return matchingBed.slug && `/product/${matchingBed.slug}/${selectedsize}`
    }
    return false
  }, [product, selected])

  // Convenience check if compare button should be visible
  const canBeCompared = useMemo(() => {
    return (
      product &&
      product.categories &&
      (product.categories.some(({ name }) => name == "Mattresses") ||
        product.categories.some(({ name }) => name == "Beds"))
    )
  }, [product, productType])

  // Fetch feverTree instalment data if applicable
  useEffect(() => {
    // Inclusive cutoff. If the price is equal to or larger, then credit is available
    const fevertree_instalment_cutoff = 499

    const final_price = getCompletePrice(selected)
    const rounded_price = Math.round(final_price)

    // Only fetch fevertree instalment if this is larger than or equal to the cutoff
    // and the instalment hasn't been fetched yet
    if (
      selected &&
      rounded_price >= fevertree_instalment_cutoff &&
      !feverTreeInstalments[rounded_price]
    ) {
      // Set to true initially so that the api will call happens only once
      setFeverTreeInstalments((pr) => ({
        ...pr,
        [rounded_price]: true,
      }))
      // Only query single product
      requestFeverTreeInstalment(rounded_price)
        .then((value) => {
          if (
            value &&
            value.length &&
            value[0] &&
            value[0].price &&
            value[0].instalment
          ) {
            setFeverTreeInstalments((pr) => ({
              ...pr,
              [value[0].price]: value[0].instalment,
            }))
          }
        })
        .catch((e) => {
          console.log("Fever Tree instalment request failed.")
        })
    }
  }, [selected])

  // Get all the shipping info for this product according to shipping class
  const shippingInfo = shippingClassGet(shippingClass)
  const product_sku = selected.main.sku || product.sku

  return (
    <Container grey toppad="30px" bottompad="30px">
      <Inner>
        <Crumbs product={product} />
      </Inner>

      <Container
        grey
        grid="3fr 2fr"
        gridMd="1fr"
        toppad="30px"
        bottompad="30px"
      >
        <Badge
          color={shippingInfo.textColor}
          backgroundColor={shippingInfo.backgroundColor}
        >
          {shippingInfo?.text}
        </Badge>
        <Left>
          {/* <Crumbs product={product} /> */}
          <Responsive maxWidth={windowSizes.mobile}>
            <Details
              title={product.name}
              sku={product_sku}
              rating={productRating}
              salePrice={final_price}
              oldPrice={final_regular_price}
              setGoToRatingsValue={setGoToRatingsValue}
              productStockFetched={productStockFetched}
              mobile
            />
          </Responsive>

          {/* <Space /> */}
          <Sliders cols={1}>
            <Slider rawImages={imagesInSlider} productName={product.name} />
            <Plus className="plus">Plus</Plus>
          </Sliders>
          {promotionalItems.length > 0 &&
            promotionalItems.map((pr, idx) => (
              <PromotionalItem key={idx}>
                <img src={pr?.images?.[0]?.localFile?.publicURL} />
                <div className="margins">
                  <div className="textcontainer">
                    <div className="text">FREE with this purchase!</div>
                  </div>
                  <Link className="product-name" to={`/product/${pr.slug}/`}>
                    {pr.name}
                  </Link>
                  <div className="small-print">
                    {"Subject to stock availability"}
                  </div>
                </div>
              </PromotionalItem>
            ))}
          <AlternativeProductLinks>
            {mattressLink && (
              <div>
                Already have a base?{" "}
                <Link to={mattressLink}>View mattress</Link>
              </div>
            )}
            {bedLink && (
              <div>
                Need a base? <Link to={bedLink}>View combo</Link>
              </div>
            )}
          </AlternativeProductLinks>
          <Conditions>
            <div>
              <SvgIcon SvgComponent={Delivery} hue="tusk" shade="080" />
              <div
                style={{
                  color: shippingInfo.deliveryTextColor,
                }}
              >
                <a href="/delivery/">{`Delivery estimate: ${shippingInfo.delay} business days.`}</a>
              </div>
              <Tooltip
                text={formatDeliveryInfo(queryResponse.shipping)}
                background={"#1a293c"}
                minWidth="200px"
              >
                <Circle>
                  <SvgIcon hue="white" shade="000" SvgComponent={Info} />
                </Circle>
              </Tooltip>
            </div>
            <span className="right">*E&OE*</span>
          </Conditions>
          {shippingInfo.delay === `1 - 2` && (
            <Conditions>
              <div>
                <SvgIcon SvgComponent={Delivery} hue="tusk" shade="080" />
                <div
                  style={{
                    color: shippingInfo.deliveryTextColor,
                  }}
                >
                  <a href="/delivery/">{`Same-day delivery available if ordered before 12H00 on weekdays.`}</a>
                </div>
                <div></div>
              </div>
              <span className="right">*E&OE*</span>
            </Conditions>
          )}
          {/*<MealsOnWheels*/}
          {/*  price={final_price}*/}
          {/*  hide_mobile={true}*/}
          {/*  hide_desktop={false}*/}
          {/*></MealsOnWheels>*/}
        </Left>
        <Right>
          <Responsive minWidth={windowSizes.mobile}>
            <Details
              title={product.name}
              sku={product_sku}
              rating={productRating}
              salePrice={final_price}
              oldPrice={final_regular_price}
              setGoToRatingsValue={setGoToRatingsValue}
              productStockFetched={productStockFetched}
            />
            <Space />
          </Responsive>
          <Filter
            // title={productType == "bundle" ? "Bed Size" : "Mattress Size"}
            title={"Select Option"}
            selectorTag="main"
            options={variations}
            setSelected={setSelected}
            selected={selected}
            quantity={quantity}
            onQuantityChange={setQuantity}
            onCounterFocusChange={setCounterFocus}
            counterFocus={counterFocus}
            maxStock={maxSelectableStock}
            includeSelector={productType == "simple" ? false : true}
            quantityOnly={productType == "simple"}
            disableDropdown={!productStockFetched}
          />
          {/*<CreditOptions*/}
          {/*  feverTree={{*/}
          {/*    amount: feverTreeInstalments[rounded_price],*/}
          {/*  }}*/}
          {/*  feverTreeSixMonths={{ amount: final_price }}*/}
          {/*  payflex={{ amount: final_price }}*/}
          {/*  zeropay={{ amount: final_price }}*/}
          {/*/>*/}
          <Space />
          {apiError && (
            <>
              <ScaryNote dangerouslySetInnerHTML={{ __html: apiError }} />
              <Space />
            </>
          )}
          <Grid>
            <Button
              SvgComponent={ArrowRight}
              label="ADD TO CART"
              onClick={handleAddToCart}
              loading={addToCartLoading}
              // disabled={!productStockFetched}
            />
            {canBeCompared && (
              <Button
                label="add to compare"
                SvgComponent={ListCheck}
                color="#223247"
                onClick={() => {
                  addToCompare(product.wordpress_id)
                  setCompareDrawerOpen(true)
                }}
              />
            )}
          </Grid>
          <CreditOptions
            feverTree={{
              amount: feverTreeInstalments[rounded_price],
            }}
            feverTreeSixMonths={{ amount: final_price }}
            payflex={{ amount: final_price }}
            zeropay={{ amount: final_price }}
          />
          <FloatCreditNote>
            <div class={"icon-left"}>
              <GatsbyImage
                image={float}
                style={{ maxHeight: "45px", maxWidth: "160px" }}
                imgStyle={{
                  maxHeight: "45px",
                  maxWidth: "160px",
                  objectFit: "contain",
                  padding: "10ox 15px 10px 0",
                }}
                placeholder="none"
                objectFit="contain"
                objectPosition="50% 50%"
                alt="float-logo"
              />
            </div>
            <div class={"text-right"}>
              Buy now. Pay way later. 4x zero interest, zero fees installments.
              No applications. No credit checks.
            </div>
          </FloatCreditNote>
          {/*<MealsOnWheels*/}
          {/*  price={final_price}*/}
          {/*  hide_mobile={false}*/}
          {/*  hide_desktop={true}*/}
          {/*></MealsOnWheels>*/}
        </Right>
      </Container>
    </Container>
  )
}

const PromotionalItem = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 140px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  box-shadow: rgba(119, 132, 142, 0.19) 0px 10px 15px -7px;
  background: white;
  img {
    height: 100%;
    margin: 10px 20px;
  }
  .margins {
    span,
    div,
    a {
      margin: 5px 0;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      line-height: 1.2;
      margin: 5px;
    }
  }
  .text {
    background: rgb(96, 196, 232);
    color: white;
    padding: 2px 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 18px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      background: none;
      padding: 5px;
      margin: 2px;
      font-size: 14px;
    }
  }
  .textcontainer {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      background: rgb(96, 196, 232);
      border-radius: 4px;
    }
  }
  .product-name {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 14px;
    }
  }
  .small-print {
    font-weight: 300;
    font-size: 12px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 10px;
    }
  }
`

const Plus = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background: #b8daea;
  color: #235e79;
  border-radius: 4px;
  display: none;
`

const FloatCreditNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  border-radius: 3px;
  color: #1a293c;
  font-size: 12px;
  padding: 10px;
  border: 1px solid #f10e5f;
  .icon-left {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .text-right {
    flex-grow: 1;
    margin-left: 10px;
  }
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const getFluidImagesFromProduct = (product) => {
  const { images: imgs } = product
  return imgs.map(getFluid)
}

// takes an object and returns value at path or undefined
const getFluid = (obj) =>
  path(["localFile", "childImageSharp", "gatsbyImageData"], obj)

// Get the full sale price of the selected variation
const getCompletePrice = (obj) =>
  Object.values(obj).reduce((acc, cur) => {
    return (acc += parseFloat(cur.price))
  }, 0)
// Get the full regular price of the selected variation
const getCompleteRegularPrice = (obj) =>
  Object.values(obj).reduce((acc, cur) => {
    return (acc += parseFloat(cur.regular_price))
  }, 0)

// ====================
// 	     STYLES
// ====================
const Circle = styled.div`
  border-radius: 200px;
  background: #9ba5ae;
  transform: scale(0.8);
`

const ScaryNote = styled.p`
  text-align: center;
  color: #fd3237;
`

const Sliders = styled.div`
  position: relative;

  ${({ cols }) =>
    cols === 2 &&
    "display:grid; grid-template-columns:repeat(2,1fr);grid-gap:20px;"}

  ${({ cols }) =>
    cols === 2 &&
    ".wrapper-slide{height:375px;} .container-slide{height:375px;}"}

		@media (max-width: ${({ theme }) => theme.breakSmall}) {
    ${({ cols }) =>
      cols === 2 &&
      ".wrapper-slide{height:275px;} .container-slide{height:275px;} grid-gap: 5px;"}

    ${({ cols }) => cols === 2 && ".plus{display:block;}"}
  }
`

const Badge = styled.div`
  border-radius: 2px;
  text-transform: uppercase;
  color: ${({ color }) => color};
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 0px 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    top: -20px;
  }
`

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  button {
    margin-bottom: 10px;
    justify-content: space-between;
    > div.text {
      width: 100%;
    }
  }

  .compare {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 0.2s all ease-in-out;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      padding: 10px;
      border: 1px solid #b3b3b3;
      border-radius: 3px;
      margin: 10px 0;
    }
    &:hover {
      /* transform: translateY(-3px); */
    }
  }
`

const AlternativeProductLinks = styled.div`
  color: #3e4b5c;
  margin: 10px 0 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: start;
  a {
    color: #0988bb;
    font-weight: bold;
  }
`
const Conditions = styled.div`
  color: #3e4b5c;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    color: #0998d2;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    > span.icon:first-child {
      margin-right: 10px;
    }
    > div:last-child {
      margin-left: 10px;
    }
  }

  .right {
    color: #9ba5ae;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    justify-content: space-between;
  }
`

const Left = styled.div``
const Right = styled.div``

const Space = styled.div`
  height: 20px;
`

// ======================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ======================

const QUERY = graphql`
  {
    shipping: localWpGraphQlShippingInfo {
      radius_one
      radius_one_cost
      radius_one_time
      radius_two
      radius_two_cost
      radius_two_time
      radius_three
      radius_three_cost
      radius_three_time
      radius_four
      radius_four_cost
      radius_four_time
    }
    floatImage: file(relativePath: { eq: "float.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export default memo(ProductVariationSelector)
